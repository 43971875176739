import React from 'react';
import { useQuery } from '@apollo/client';
import { navigate } from 'gatsby-link';
import { Flex, Heading, Text, Box } from 'rebass/styled-components';
import Layout from '../../components/Layout';
import search from '../../graphql/queries/search.graphql';
import Loader from '../../components/Loader';
import TrainingThumbnail from '../../components/TrainingThumbnail';

const SearchRoute = ({ searchString }) => {
  const { data, loading } = useQuery(search, {
    variables: {
      searchString,
    },
  });
  if (!searchString && typeof window !== 'undefined') {
    navigate('/');
    return null;
  }
  if (loading || !data) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }
  if (data.search.length === 0) {
    return (
      <Layout>
        <Flex variant={'wrapper'} flexDirection={'column'} color={'text'}>
          <Heading
            marginLeft={20}
            mb={[25, 50]}
            fontSize={[24, 32]}
            fontFamily={'Oswald'}
            color={'text'}
          >
            <Text> Wyniki wyszukiwania: {searchString}</Text>
            <Box
              as="hr"
              sx={{
                bg: 'gray',
                height: 0.1,
                marginBottom: 20,
              }}
            />
            <Text
              marginLeft={[10, 50]}
              marginTop={[10, 40]}
              fontSize={[20, 24]}
            >
              Brak wyników wyszukiwania..
            </Text>
            <Box
              as="hr"
              marginTop={50}
              sx={{
                bg: 'gray',
                border: 0,
                height: 1,
              }}
            />
          </Heading>
        </Flex>
      </Layout>
    );
  }

  const trainings = [];
  const onlineTrainings = [];

  data.search.forEach((el) => {
    switch (el.type) {
      case 'training':
        return trainings.push(el);
      case 'onlineTraining':
        return onlineTrainings.push(el);
      default:
        return null;
    }
  });

  return (
    <Layout>
      <Flex variant={'wrapper'} flexDirection={'column'} color={'text'}>
        <Heading
          marginLeft={20}
          mb={[25, 50]}
          fontSize={[24, 32]}
          fontFamily={'Oswald'}
        >
          Wyniki wyszukiwania: {searchString}
          <Box
            as="hr"
            sx={{
              bg: 'gray',
              height: 0.1,
              marginBottom: 20,
            }}
          />
          {trainings.length ? (
            <>
              <Box marginTop={10}>e-szkolenia</Box>
              <Box
                as="hr"
                sx={{
                  bg: 'gray',
                  border: 0,
                  height: 1,
                }}
              />
              <Flex flexDirection={'row'} color={'text'} flexWrap={'wrap'}>
                {trainings.map((el) => (
                  <>
                    <TrainingThumbnail
                      data={el}
                      key={el._id}
                      type={'e-szkolenia'}
                    />
                  </>
                ))}
              </Flex>
            </>
          ) : null}
          {onlineTrainings.length ? (
            <>
              <Box marginTop={10}>szkolenia online</Box>
              <Box
                as="hr"
                sx={{
                  bg: 'gray',
                  border: 0,
                  height: 1,
                }}
              />
              <Flex flexDirection={'row'} color={'text'} flexWrap={'wrap'}>
                {onlineTrainings.map((el) => (
                  <>
                    <TrainingThumbnail
                      data={el}
                      key={el._id}
                      type={'szkolenia-online'}
                    />
                  </>
                ))}
              </Flex>
            </>
          ) : null}
        </Heading>
      </Flex>
    </Layout>
  );
};

export default SearchRoute;
